import { Typography } from 'features/ui/typography/typography';
import { router } from 'routing/routes';
import paths from 'routing/utils';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setSelectedTileId, setTempRecipient } from 'store/slices/storyCreatorSlice';
import { ElementTile } from '../elementTile';
import { TileProps } from './types.elementTiles';

export const RecipientTile = (props: TileProps) => {
  const loggedUser = useAppSelector(state => state.authSlice.loggedUser);
  const storyCreatorSlice = useAppSelector(state => state.storyCreatorSlice);
  const dispatch = useAppDispatch();

  const recipient = loggedUser?.childProfiles.find(p => p.id === storyCreatorSlice.recipient);
  const isRecipientSelected = Boolean(storyCreatorSlice.recipient);

  const onAction = () => {
    dispatch(setSelectedTileId(props.tileId));
    dispatch(setTempRecipient(undefined));
    router.navigate(props.customCreator ? paths.storyCreator.custom.recipient.base : paths.storyCreator.template.recipient.base);
  };

  const getImageAndDir = () => {
    if (isRecipientSelected && recipient?.image) {
      return { image: recipient.image, dir: loggedUser?.id };
    }
    if (isRecipientSelected) {
      return { image: 'recipient.png', dir: 'common' };
    }
    return { image: 'random.png', dir: 'common' };
  };

  const { image, dir } = getImageAndDir();

  return (
    <ElementTile tileId={props.tileId} action={onAction} filename={image} directory={dir}>
      <>
        <Typography variant="h4">Dla kogo</Typography>
        <Typography variant="description">{isRecipientSelected ? recipient?.name : 'Nikt konkretny'}</Typography>
      </>
    </ElementTile>
  );
};
