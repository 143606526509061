import { Typography } from 'features/ui/typography/typography';
import _ from 'lodash';
import { router } from 'routing/routes';
import paths from 'routing/utils';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setSelectedTileId } from 'store/slices/storyCreatorSlice';
import { ElementTile } from '../elementTile';
import { TileProps } from './types.elementTiles';

export const ElementsTile = (props: TileProps) => {
  const storyCreatorSlice = useAppSelector(state => state.storyCreatorSlice);
  const dispatch = useAppDispatch();

  const onAction = () => {
    dispatch(setSelectedTileId(props.tileId));
    router.navigate(paths.storyCreator.custom.elements);
  };

  return (
    <ElementTile
      tileId={props.tileId}
      action={onAction}
      filename={_.isEmpty(storyCreatorSlice.elements?.predefinedTags) ? 'random.png' : 'story_elements.png'}
      directory="common"
    >
      <>
        <Typography variant="h4">Elementy w bajce</Typography>
        <Typography variant="description">
          {_.isEmpty(storyCreatorSlice.elements?.predefinedTags)
            ? 'Brak wybranych elementów'
            : storyCreatorSlice.elements?.predefinedTags?.length}
        </Typography>
      </>
    </ElementTile>
  );
};
