import { Typography } from 'features/ui/typography/typography';
import { router } from 'routing/routes';
import paths from 'routing/utils';
import { TemplateParam } from 'shared/types/template';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setSelectedTileId } from 'store/slices/storyCreatorSlice';
import { ElementTile } from '../elementTile';
import { TileProps } from './types.elementTiles';

type OtherTileProps = TileProps & {
  other: TemplateParam;
};

export const OtherTile = (props: OtherTileProps) => {
  const { other } = props;
  const storyCreatorSlice = useAppSelector(state => state.storyCreatorSlice);
  const dispatch = useAppDispatch();

  const onAction = () => {
    dispatch(setSelectedTileId(props.tileId));
    router.navigate(paths.storyCreator.template.other + `/${other.key}/${other.label}`);
  };

  const getImageAndDir = () => {
    const otherSelected = storyCreatorSlice.other?.[other.key];
    const otherImage = other.image;
    if (otherSelected && otherImage) {
      return { image: otherImage, dir: 'common' };
    }
    if (otherSelected) {
      return { image: `${storyCreatorSlice.templateStoryId}.png`, dir: 'common' };
    }
    return { image: 'random.png', dir: 'common' };
  };

  const { image, dir } = getImageAndDir();

  return (
    <ElementTile tileId={props.tileId} key={other.value} action={onAction} filename={image} directory={dir}>
      <>
        <Typography variant="h4">{other.label}</Typography>
        <Typography variant="description">{storyCreatorSlice.other?.[other.key] ?? 'Brak'}</Typography>
      </>
    </ElementTile>
  );
};
