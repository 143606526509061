import { RouteLayout } from 'features/ui/layout/routeLayout';
import { Typography } from 'features/ui/typography/typography';
import { ProgressLoader } from './progressLoader';

export const StoryCreatorLoader = () => (
  <RouteLayout>
    <div className="d-flex flex-column justify-content-center align-items-center gap-3 pt-5 mx-3 text-center">
      <ProgressLoader />
      <Typography variant="h1">Twoja bajka właśnie się tworzy.</Typography>
      <Typography variant="description" classNames="pt-4">
        Może to zająć około 5 min. Czas tworzenia Twojej bajki zależy od obciążenia naszych serwerów.
      </Typography>
    </div>
  </RouteLayout>
);
