import { Typography } from 'features/ui/typography/typography';
import { router } from 'routing/routes';
import paths from 'routing/utils';
import { AddIcon } from 'shared/icons/addIcon';
import { pxToRem } from 'shared/utils/commonUtils';
import { useAppSelector } from 'store/hooks';
import { AvatarPreview } from '../avatarPreview';
import { AVATAR_SIZE } from '../utils';
import { cWhite } from 'shared/utils/styleCommon';

export const ChildRibbon = () => {
  // redux
  const loggedUser = useAppSelector(state => state.authSlice.loggedUser);

  return (
    <>
      <Typography variant="h5">Profile dzieci</Typography>
      <div className="d-flex flex-wrap  align-items-start gap-4 mt-4">
        {loggedUser?.childProfiles.map(p => (
          <AvatarPreview
            editable
            key={p.id}
            name={p.name}
            filename={p.image}
            onClickCallback={() => router.navigate(paths.profile.characters.editChild + `/${p.id}`)}
          />
        ))}
        <div
          style={{ maxWidth: pxToRem(AVATAR_SIZE), backgroundColor: cWhite }}
          role="button"
          className="d-flex flex-column justify-content-start align-items-start"
          onClick={() => router.navigate(paths.profile.characters.createChild)}
        >
          <AddIcon />
          <Typography variant="description" classNames="text-center">
            Dodaj profil
          </Typography>
        </div>
      </div>
    </>
  );
};
