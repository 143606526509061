import { Typography } from 'features/ui/typography/typography';
import { router } from 'routing/routes';
import paths from 'routing/utils';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setSelectedTileId } from 'store/slices/storyCreatorSlice';
import { ElementTile } from '../elementTile';
import { TileProps } from './types.elementTiles';

export const EpochTile = (props: TileProps) => {
  const storyCreatorSlice = useAppSelector(state => state.storyCreatorSlice);
  const dispatch = useAppDispatch();

  const onAction = () => {
    dispatch(setSelectedTileId(props.tileId));
    router.navigate(paths.storyCreator.custom.epoch);
  };

  const getImageAndDir = () => {
    const epoch = storyCreatorSlice.epoch?.predefinedEpoch;
    const isEpochPicked = Boolean(epoch);
    const doesEpochHaveImage = Boolean(epoch?.image);
    const epochImage = epoch?.image;

    if (isEpochPicked) {
      if (doesEpochHaveImage) {
        return {
          image: epochImage,
          dir: 'common'
        };
      }
      return { image: 'epoch.png', dir: 'common' };
    }
    return { image: 'random.png', dir: 'common' };
  };

  const { image, dir } = getImageAndDir();

  return (
    <ElementTile tileId={props.tileId} action={onAction} filename={image} directory={dir}>
      <>
        <Typography variant="h4">Czas</Typography>
        <Typography variant="description">{storyCreatorSlice.epoch?.predefinedEpoch?.value ?? 'Losowy'}</Typography>
      </>
    </ElementTile>
  );
};
