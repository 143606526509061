import { Typography } from 'features/ui/typography/typography';
import { router } from 'routing/routes';
import paths from 'routing/utils';
import { convertLengthToString } from 'shared/types/story';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setSelectedTileId } from 'store/slices/storyCreatorSlice';
import { ElementTile } from '../elementTile';
import { TileProps } from './types.elementTiles';

export const StoryLengthTile = (props: TileProps) => {
  const storyCreatorSlice = useAppSelector(state => state.storyCreatorSlice);
  const dispatch = useAppDispatch();

  const onAction = () => {
    dispatch(setSelectedTileId(props.tileId));
    router.navigate(paths.storyCreator.custom.length);
  };

  return (
    <ElementTile
      tileId={props.tileId}
      action={onAction}
      filename={storyCreatorSlice.storyLength ? 'story_length.png' : 'random.png'}
      directory="common"
    >
      <>
        <Typography variant="h4">Długość bajki</Typography>
        <Typography variant="description">
          {storyCreatorSlice.storyLength ? convertLengthToString(storyCreatorSlice.storyLength) : 'Brak'}
        </Typography>
      </>
    </ElementTile>
  );
};
