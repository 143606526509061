import { AuthorityType } from './account';
import { Moral } from './moral';
import { Tag } from './tag';
import { World } from './world';

export interface User {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  activated: boolean;
  authorities?: AuthorityType[];
  subscription?: Subscription;
  subscriptionExpiration?: number;
  childProfiles: ChildProfile[];
  heroes: Hero[];
  morals: Moral[];
  worlds: World[];
  cards?: Card[];
  tokens: number;
  userStoryInfo: UserStoryInfo[];
  termsAccepted: boolean;
  playbackRate: number;
}

export interface UserStoryInfo {
  storyId: string;
  favorite: boolean;
  pauseTime: number;
  read: boolean;
  rating?: Rating;
}

export interface Rating {
  rating: number;
  comment?: string;
}

export interface Subscription {
  type: SubscriptionType;
  price: number;
  currency: Currency;
  tokens: number;
}

export enum SubscriptionType {
  FREE = 'FREE',
  BASIC = 'BASIC',
  ADVANCED = 'ADVANCED'
}

export enum Currency {
  PLN = 'PLN'
}

export interface ChildProfile {
  id: string;
  name: string;
  age: number;
  gender: Gender;
  image?: string;
  tags: Tag[];
}

export interface Hero {
  id: string;
  name: string;
  category: string;
  subcategory?: string;
  image?: string;
}

export interface Card {
  vendor: string;
  shortCode: string;
  tokenExpiration: number;
}

export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE'
}

export namespace Users {
  export const toString = <T extends { firstName?: string; lastName?: string; email?: string }>(obj?: T): string =>
    obj?.firstName && obj?.lastName ? `${obj.firstName} ${obj.lastName}` : obj?.email ?? 'Użytkownik nieznany';

  export const getInitials = <T extends { firstName?: string; lastName?: string }>(obj?: T): string =>
    obj?.firstName && obj?.lastName ? obj?.firstName?.[0] + obj?.lastName?.[0] : 'b.d.';
}
