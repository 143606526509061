import { CustomImage, ThumbnailSize } from 'features/ui/image/customImage';
import { Typography } from 'features/ui/typography/typography';
import { EditIcon } from 'shared/icons/editIcon';
import { cGrey200, cPrimary, cSecondary } from 'shared/utils/styleCommon';

type Props = {
  name?: string;
  filename?: string;
  directory?: string;
  editable?: boolean;
  isSelected?: boolean;
  onClickCallback: () => void;
};

export const PickerItemPreview = (props: Props) => {
  return (
    <div
      style={{
        position: 'relative',
        maxWidth: '160px'
      }}
      role="button"
      onClick={props.onClickCallback}
    >
      <CustomImage
        directory={props.directory}
        filename={props.filename}
        styles={{
          borderRadius: '10px',
          outline: props.isSelected ? `2px solid ${cPrimary}` : props.filename ? 'none' : `2px solid ${cGrey200}`
        }}
        width="160px"
        height="160px"
        thumbnail={ThumbnailSize.THUMBNAIL_360X360}
        noImagePlaceholder
        skeleton
      />

      {props.editable && (
        <div style={{ position: 'absolute', top: -4, right: -4 }}>
          <EditIcon />
        </div>
      )}

      <Typography variant="description" classNames="pt-2" styles={{ color: props.isSelected ? cPrimary : cSecondary }}>
        {props.name}
      </Typography>
    </div>
  );
};
