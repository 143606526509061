import { RouteLayout } from 'features/ui/layout/routeLayout';
import { Typography } from 'features/ui/typography/typography';
import { useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import paths from 'routing/utils';
import { WhiteCloud } from 'shared/images/whiteCloud';
import { SubscriptionType } from 'shared/types/users';
import { useAppSelector } from 'store/hooks';
import unlockImg from './unlock.png';

export const Unlock = () => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [actionButtonLabel, setActionButtonLabel] = useState('');
  // redux
  const loggedUser = useAppSelector(state => state.authSlice.loggedUser);
  // other
  const navigate = useNavigate();
  let { requiredSubscription } = useParams();
  const lastElement = useRef<HTMLDivElement>(null);

  const onAction = () => {
    if (!loggedUser) {
      navigate(paths.signUp.base);
    } else if (requiredSubscription === SubscriptionType.BASIC) {
      navigate(paths.subscription.paymentMethods);
    } else {
      // TODO redirect to error
    }
  };

  // const onCancel = () => {
  //   navigate(paths.root);
  // };

  useEffect(() => {
    if (!loggedUser) {
      setTitle('Odblokuj pełną wersję i twórz bajkowy świat!');
      setDescription(
        'Wypróbuj plan Rozbudowany przez 7 dni za darmo, a później ciesz się pełnym dostępem za tylko XX,XX zł miesięcznie. Odkryj wszystkie nasze niesamowite funkcje i korzyści!'
      );
      setActionButtonLabel('Rozpocznij 7 dni za darmo');
    } else if (requiredSubscription === SubscriptionType.BASIC) {
      setTitle('Odblokuj średnią wersję i twórz bajkowy świat!');
      setDescription(
        'Ciesz się średnim dostępem i twórz w pełni spersonalizowane bajki za tylko XX,XX zł miesięcznie. Odkryj wszystkie nasze niesamowite funkcje i korzyści!'
      );
      setActionButtonLabel('Subskrybuj za XX,XX zł');
    } else if (requiredSubscription === SubscriptionType.ADVANCED) {
      setTitle('Odblokuj pełną wersję i twórz bajkowy świat!');
      setDescription(
        'Ciesz się pełnym dostępem i twórz w pełni spersonalizowane bajki za tylko XX,XX zł miesięcznie. Odkryj wszystkie nasze niesamowite funkcje i korzyści!'
      );
      setActionButtonLabel('Subskrybuj za XX,XX zł');
    } else {
      // error
    }
  }, [loggedUser, requiredSubscription]);

  return (
    <RouteLayout
      backRoute={-1}
      bottomElement={
        <div className="d-flex flex-column align-items-center gap-3 py-4">
          <Button onClick={onAction}>Zarejestruj się</Button>
          {/* <Button variant="link" onClick={onCancel}>
            Chcę korzystać z ograniczonej wersji
          </Button> */}
        </div>
      }
    >
      <div style={{ position: 'relative' }}>
        <img
          src={unlockImg}
          alt=""
          width="100%"
          onLoad={() => {
            lastElement.current?.scrollIntoView({ behavior: 'instant' });
          }}
        />
        <WhiteCloud />
      </div>
      <div className="mx-3">
        <Typography variant="h1" classNames="pb-2">
          Odkryj Magiczny Świat Bajek ze Storytailo
        </Typography>
        <Typography variant="description">
          Spersonalizowane opowieści dla dzieci, które wspierają rozwój emocjonalny i poznawczy, jednocześnie bawiąc i ucząc.
          <div className="py-2 ">
            <div className="py-2">
              <Typography variant="h4" classNames="me-2" inline>
                Spersonalizowane opowieści:
              </Typography>
              <Typography variant="description" inline>
                Pozwól aby Twoje dzieci stały się bohaterami bajek razem z innymi postaciami.
              </Typography>
            </div>
            <div className="py-2">
              <Typography variant="h4" classNames="me-2" inline>
                Bez reklam:
              </Typography>
              <Typography variant="description" inline>
                Zapewniamy bezpieczne i wolne od reklam środowisko dla Twojego dziecka.
              </Typography>
            </div>
            <div className="py-2">
              <Typography variant="h4" classNames="me-2" inline>
                Do czytania i słuchania:
              </Typography>
              <Typography variant="description" inline>
                Każdą bajkę możesz przeczytać sam lub wysłuchać wraz z delikatnym podkładem muzycznym, co dodaje magii do każdej historii.
              </Typography>
            </div>
            <div className="py-2" ref={lastElement}>
              <Typography variant="h4" classNames="me-2" inline>
                Edukacyjne treści:
              </Typography>
              <Typography variant="description" inline>
                Opowieści są starannie opracowane, aby uczyć dzieci nowych umiejętności i wiedzy w przystępny sposób.
              </Typography>
            </div>
          </div>
        </Typography>
      </div>
    </RouteLayout>
  );
};
