import _ from 'lodash';
import React, { createContext, useContext, useEffect } from 'react';
import { useInterval } from 'shared/hooks/useInterval';
import { usePrevious } from 'shared/hooks/usePrevious';
import { useIsGeneratingQuery } from 'store/api/endpoints/generatorEndpoints';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setIsGenerating, setTime } from 'store/slices/storyGeneratorSlice';

const TimerContext = createContext(0);

type Props = {
  children: React.ReactNode;
};

export const TimerProvider = (props: Props) => {
  // redux
  const isGenerating = useAppSelector(state => state.storyGeneratorSlice.isGenerating);
  const time = useAppSelector(state => state.storyGeneratorSlice.time);
  const dispatch = useAppDispatch();
  // rtk
  const { data: isGeneratingQueryData, refetch } = useIsGeneratingQuery();
  // other
  const prevIsGenerating = usePrevious(isGenerating);

  useEffect(() => {
    if (isGeneratingQueryData) {
      dispatch(setIsGenerating(true));
    } else if (isGeneratingQueryData === false) {
      dispatch(setIsGenerating(false));
    }
  }, [isGeneratingQueryData, dispatch]);

  useEffect(() => {
    if (prevIsGenerating && !isGenerating) {
      dispatch(setTime(0));
    }
  }, [prevIsGenerating, isGenerating, dispatch]);

  useInterval(
    () => {
      if (!_.isNil(time)) {
        dispatch(setTime(time + 1));
        if (time % 5 === 0) {
          // TODO hack in case of possible lack of websocket notification on apple devices (nondeterministic bug)
          refetch()
            .unwrap()
            .then(isStillGenerating => {
              if (!isStillGenerating) {
                dispatch(setIsGenerating(false));
                dispatch(setTime(0));
              }
            })
            .catch(e => console.error(e));
        }
      }
    },
    isGenerating ? 1000 : null
  );

  return <TimerContext.Provider value={!_.isNil(time) ? time : 0}>{props.children}</TimerContext.Provider>;
};

export const useTimer = () => useContext(TimerContext);
