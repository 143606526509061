import { FormError } from 'features/ui/form/formError';
import { RouteLayout } from 'features/ui/layout/routeLayout';
import { Typography } from 'features/ui/typography/typography';
import { Form } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { router } from 'routing/routes';
import paths from 'routing/utils';
import { validationMessage } from 'shared/utils/formUtils';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setOther } from 'store/slices/storyCreatorSlice';

export const OtherPicker = () => {
  // redux
  const dispatch = useAppDispatch();
  const other = useAppSelector(state => state.storyCreatorSlice.other);
  // other
  const { key, label } = useParams();

  const {
    control,
    formState: { isValid, isDirty },
    handleSubmit
  } = useForm<{ value: string }>({
    mode: 'onChange',
    defaultValues: {
      value: other?.[key ?? ''] ?? ''
    }
  });

  const onSubmit = (data: { value: string }) => {
    key && dispatch(setOther({ ...other, [`${key}`]: data.value }));
    router.navigate(paths.storyCreator.template.base);
  };

  return (
    <RouteLayout
      backRoute={paths.storyCreator.template.base}
      bottomActions={[
        {
          label: 'Zapisz',
          disabled: !isValid || !isDirty,
          submittable: true,
          formId: 'otherForm'
        }
      ]}
    >
      <div className="pt-3 mx-3">
        <Typography variant="h1" classNames="pb-5">
          Zdefiniuj element
        </Typography>

        <Form onSubmit={handleSubmit(onSubmit)} id="otherForm" noValidate>
          <Form.Group className="mb-3" controlId="otherForm.value">
            <Form.Label>{label}</Form.Label>
            <Controller
              name="value"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <Form.Control {...field} isInvalid={fieldState.invalid} max={200} required />
                  {fieldState.invalid ? <FormError error={fieldState.error?.message} /> : ''}
                </>
              )}
              rules={{ required: validationMessage.required }}
            />
          </Form.Group>
        </Form>
      </div>
    </RouteLayout>
  );
};
