import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useTimer } from 'shared/context/TimerContext';
import { rgba } from 'shared/utils/commonUtils';
import { cPrimary } from 'shared/utils/styleCommon';

export const ProgressLoader = () => {
  const count = useTimer();

  return (
    <div style={{ width: 150, height: 150 }}>
      <CircularProgressbar
        value={count}
        maxValue={300} // TODO
        text={`${count}s`}
        styles={{
          path: {
            stroke: cPrimary
          },
          trail: {
            stroke: rgba(cPrimary, 0.3)
          },
          text: {
            fill: cPrimary,
            fontSize: '28px',
            fontWeight: '700'
          }
        }}
      />
    </div>
  );
};
