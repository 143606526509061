export const Logo = () => {
  return (
    <svg width="96" height="96" viewBox="0 0 63 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect y="0.5" width="63" height="63" rx="8" fill="#112B52" />
      <path
        d="M32.7139 26.9052C32.683 26.8855 32.3527 26.7148 32.3218 26.7016C33.6221 25.664 35.8822 23.0962 37.0122 21.0669C38.4776 18.44 39.3755 13.2781 36.264 11.6954C34.8244 10.9598 32.8687 11.5903 31.5839 12.444C29.5715 13.7772 27.6571 15.8065 26.2897 18.0985C24.5147 21.0735 22.8841 25.9004 25.4693 28.9805C25.6086 29.1512 25.7634 29.3023 25.913 29.4599C23.2298 30.6354 20.5208 30.5501 17.9718 29.7751C17.0017 29.4796 13.627 34.3262 14.9996 34.8188C16.9036 35.5609 19.2876 34.891 21.1349 34.1686C23.2814 33.328 25.3248 32.1328 27.2959 30.7602C28.9884 32.159 30.9234 33.2032 32.6675 34.208C35.0256 35.5675 36.9245 37.7872 37.136 40.6111C37.5437 46.1211 33.6427 48.9385 29.8914 48.9385C26.1401 48.9385 25.9233 46.4232 25.8717 45.5169C25.7943 44.1772 26.6457 43.1198 26.4703 40.9395C26.2949 38.8183 25.1803 37.5376 23.1989 37.4917C21.429 37.4457 19.9377 39.4093 19.8294 42.1282C19.6591 46.456 22.461 51.0991 27.105 51.9922C30.9544 52.7343 35.361 51.8018 38.359 48.9188C42.0639 45.3593 44.6077 37.6165 40.1443 32.1393C38.2042 29.7554 35.1701 28.2055 32.7088 26.9118L32.7139 26.9052ZM30.5622 17.1594C31.4858 16.5289 33.1216 16.2202 33.5963 17.6716C34.4786 20.3642 31.9502 24.0813 30.6912 25.6837C30.6912 25.6837 30.686 25.6903 30.6809 25.6968C29.3651 24.7708 28.1886 23.4771 28.2557 21.5594C28.3227 19.7666 29.3496 17.9934 30.5674 17.1594H30.5622Z"
        fill="#EC8D29"
      />
    </svg>
  );
};
