import { router } from 'routing/routes';
import paths from 'routing/utils';
import { AboutIcon } from 'shared/icons/aboutIcon';
import { FacebookIcon } from 'shared/icons/facebookIcon';
import { HelpIcon } from 'shared/icons/helpIcon';
import { LogoutIcon } from 'shared/icons/logoutIcon';
import { SettingsIcon } from 'shared/icons/settingsIcon';
import { TermsIcon } from 'shared/icons/termsIcon';

interface BottomMenuOption {
  icon: JSX.Element;
  label: string;
  action: () => void;
  route: string;
}

export const bottomMenuOptions: BottomMenuOption[] = [
  // { // TODO uncomment when subscription plans are done by business
  //   action: () => router.navigate(paths.profile.plans),
  //   icon: <AccountBalanceIcon />,
  //   label: 'Subskrybcja',
  //   route: paths.profile.plans
  // },
  {
    action: () => window.open('https://www.facebook.com/storytailo', '_blank', 'noreferrer'),
    icon: <FacebookIcon />,
    label: 'Nowości i aktualizacje',
    route: paths.profile.settings.base
  },
  {
    action: () => router.navigate(paths.profile.settings.base),
    icon: <SettingsIcon />,
    label: 'Ustawienia',
    route: paths.profile.settings.base
  },
  {
    action: () => window.open('mailto:komunikacja@storytailo.com', '_blank', 'noreferrer'),
    icon: <HelpIcon />,
    label: 'Pomoc',
    route: paths.profile.help
  },
  {
    action: () => window.open('https://storytailo.com/', '_blank', 'noreferrer'),
    icon: <AboutIcon />,
    label: 'O aplikacji',
    route: paths.profile.about
  },
  {
    action: () => router.navigate(paths.profile.terms),
    icon: <TermsIcon />,
    label: 'Regulamin',
    route: paths.profile.terms
  },
  {
    action: () => router.navigate(paths.logout),
    icon: <LogoutIcon />,
    label: 'Wyloguj się',
    route: paths.logout
  }
];
