import { Typography } from 'features/ui/typography/typography';
import { router } from 'routing/routes';
import paths from 'routing/utils';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setSelectedTileId, setTempWorld } from 'store/slices/storyCreatorSlice';
import { ElementTile } from '../elementTile';
import { TileProps } from './types.elementTiles';

export const WorldTile = (props: TileProps) => {
  const loggedUser = useAppSelector(state => state.authSlice.loggedUser);
  const storyCreatorSlice = useAppSelector(state => state.storyCreatorSlice);
  const dispatch = useAppDispatch();

  const onAction = () => {
    dispatch(setSelectedTileId(props.tileId));
    dispatch(setTempWorld(undefined));
    router.navigate(paths.storyCreator.custom.world.base);
  };

  const getImageAndDir = () => {
    const world = storyCreatorSlice.world?.world;
    const isWorldPicked = Boolean(world);
    const doesWorldHaveImage = Boolean(world?.image);
    const isWorldPredefined = Boolean(world?.predefined);
    const worldImage = world?.image;

    if (isWorldPicked) {
      if (doesWorldHaveImage) {
        return {
          image: worldImage,
          dir: isWorldPredefined ? 'common' : loggedUser?.id
        };
      }
      return { image: 'story_world.png', dir: 'common' };
    }
    return { image: 'random.png', dir: 'common' };
  };

  const { image, dir } = getImageAndDir();

  return (
    <ElementTile tileId={props.tileId} action={onAction} filename={image} directory={dir}>
      <>
        <Typography variant="h4">Świat</Typography>
        <Typography variant="description">{storyCreatorSlice.world?.world?.value ?? 'Losowy'}</Typography>
      </>
    </ElementTile>
  );
};
