import { Terms } from 'shared/types/terms';
import { HTTP_METHODS, apiSlice } from '../api';

const extendedApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getTerms: builder.query<Terms, void>({
      query: () => ({
        url: '/public/terms/0.1',
        method: HTTP_METHODS.GET
      })
    })
  }),
  overrideExisting: false
});

export const { useGetTermsQuery } = extendedApi;
