import _ from 'lodash';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { pxToRem } from 'shared/utils/commonUtils';
import { cGrey200 } from 'shared/utils/styleCommon';
import { CustomImage } from '../image/customImage';
import { BottomNavigation } from '../navigation/bottomNavigation';
import { TopNavigation, TopNavigationAction } from '../navigation/topNavigation';
import { JustifyCenter } from './justifyCenter';

type Props = {
  actions?: TopNavigationAction[];
  backRoute?: string | -1;
  backRouteState?: any;
  backRouteAdditionalAction?: () => void;
  children: React.ReactNode;
  bottomActions?: StickyBottomAction[];
  bottomElement?: JSX.Element;
  hideBottomNavigation?: boolean;
};

export interface StickyBottomAction {
  action?: () => void;
  disabled?: boolean;
  label: string;
  formId?: string;
  submittable?: boolean;
}

export const RouteLayout = (props: Props) => {
  return (
    <>
      <div className="w-100 d-flex flex-column h-100" style={{ minHeight: pxToRem(1) }}>
        <div className="w-100 h-100" style={{ zIndex: 1, minHeight: pxToRem(1) }}>
          <div className="d-flex flex-column h-100">
            {(!_.isNil(props.backRoute) || !_.isNil(props.actions)) && (
              <TopNavigation
                actions={props.actions}
                backRoute={props.backRoute}
                backRouteState={props.backRouteState}
                backRouteAdditionalAction={props.backRouteAdditionalAction}
              />
            )}
            <Container style={{ overflowY: 'auto', maxWidth: 'none' }} className="h-100">
              <Row className="justify-content-sm-center h-100">
                <Col xxl={6} xl={6} lg={6} md={6} sm={8} xs={12} style={{ padding: 0 }}>
                  {props.children}
                </Col>
              </Row>
            </Container>
            {props.bottomActions && !_.isEmpty(props.bottomActions) && (
              <div style={{ position: 'relative', height: 'auto' }}>
                <CustomImage
                  directory="common"
                  filename="spiral2.png"
                  styles={{
                    position: 'absolute',
                    zIndex: -1,
                    right: 0,
                    left: 0,
                    top: 0,
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    opacity: 0.5
                  }}
                />
                <div style={{ position: 'relative' }}>
                  {props.bottomActions.map((ba, idx) => (
                    <JustifyCenter classNames="py-3" styles={{ borderTop: `1px solid ${cGrey200}` }} key={idx}>
                      <Button
                        disabled={ba.disabled}
                        onClick={ba.submittable ? undefined : ba.action}
                        type={ba.submittable ? 'submit' : 'button'}
                        form={ba.formId}
                      >
                        {ba.label}
                      </Button>
                    </JustifyCenter>
                  ))}
                </div>
              </div>
            )}

            {props.bottomElement && (
              <Container style={{ maxWidth: 'none' }}>
                <Row className="justify-content-sm-center" style={{ borderTop: `1px solid ${cGrey200}` }}>
                  <Col xxl={6} xl={6} lg={6} md={6} sm={8} xs={12} style={{ padding: 0 }}>
                    {props.bottomElement}
                  </Col>
                </Row>
              </Container>
            )}
          </div>
        </div>
      </div>
      {!props.hideBottomNavigation && (
        <div style={{ zIndex: 2 }}>
          <BottomNavigation />
        </div>
      )}
    </>
  );
};
