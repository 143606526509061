import { skipToken } from '@reduxjs/toolkit/query';
import { AlertPage } from 'features/ui/alert-page/alertPage';
import { RouteLayout } from 'features/ui/layout/routeLayout';
import { Typography } from 'features/ui/typography/typography';
import { useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { useNavigate, useSearchParams } from 'react-router-dom';
import paths from 'routing/utils';
import { useActivateQuery } from 'store/api/endpoints/accountEndpoints';
import { useAppSelector } from 'store/hooks';

export const ActivateAccount = (): JSX.Element => {
  // rtk
  const [searchParams] = useSearchParams();
  const key = searchParams.get('key');
  const { isError, isSuccess } = useActivateQuery(key ?? skipToken);
  // redux
  const loggedUser = useAppSelector(state => state.authSlice.loggedUser);
  // other
  const navigate = useNavigate();

  useEffect(() => {
    loggedUser && navigate(paths.signIn, { replace: true });
  }, [loggedUser, navigate]);

  if (isError) {
    return (
      <AlertPage
        status="error"
        actionName="Wróć do strony głównej"
        actionCallback={() => navigate(paths.signIn)}
        title="Ups, coś poszło nie tak..."
        content={
          <Typography variant="description" classNames="pt-4 text-center">
            Spróbuj ponownie później
          </Typography>
        }
      />
    );
  }

  if (isSuccess) {
    return (
      <AlertPage
        status="success"
        actionName="Wróć do logowania"
        actionCallback={() => navigate(paths.signIn)}
        title="Adres mailowy został pomyślnie potwierdzony."
        // TODO embed google form when figma project and form are ready
        // content={
        //   <>
        //     <iframe
        //       src="https://docs.google.com/forms/d/e/1FAIpQLSeXutrUkZcYHLlSwflRE2SR5JErFtfR0rRTckR6JDkh8bij7w/viewform?embedded=true"
        //       width={640}
        //       height={922}
        //       frameBorder={0}
        //       marginHeight={0}
        //       marginWidth={0}
        //       title="Google forms"
        //     >
        //       Ładuję…
        //     </iframe>
        //   </>
        // }
        content={
          <Typography variant="description" classNames="pt-4 text-center">
            Możesz się teraz zalogować do swojego konta.
          </Typography>
        }
      />
    );
  }

  return (
    <RouteLayout>
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Ładowanie...</span>
      </Spinner>
    </RouteLayout>
  );
};
