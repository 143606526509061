import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { PredefinedEpoch } from 'shared/types/epoch';
import { Moral } from 'shared/types/moral';
import { PredefinedNarrator } from 'shared/types/narrator';
import { PredefinedTag } from 'shared/types/predefinedTag';
import { StoryLength } from 'shared/types/story';
import { World } from 'shared/types/world';
import { PredefinedWritingStyle } from 'shared/types/writingStyle';

const SLICE_NAME = 'storyCreatorSlice';

export interface Random {
  random: boolean;
}

export interface Character {
  id: string;
  childProfile: boolean;
}

export interface HeroParam extends Random {
  character?: Character;
}

export interface WorldParam extends Random {
  world?: World;
}

export interface MoralParam extends Random {
  moral?: Moral;
}

export interface EpochParam extends Random {
  predefinedEpoch?: PredefinedEpoch;
}

export interface WritingStyleParam extends Random {
  predefinedWritingStyle?: PredefinedWritingStyle;
}

export interface TagParam extends Random {
  predefinedTags?: PredefinedTag[];
}

interface OtherParameter {
  [key: string]: string;
}

interface StoryCreatorState {
  recipient?: string;
  tempRecipient?: string;
  mainHero?: HeroParam;
  tempMainHero?: HeroParam;
  sideHero?: HeroParam;
  tempSideHero?: HeroParam;
  world?: WorldParam;
  tempWorld?: WorldParam;
  moral?: MoralParam;
  tempMoral?: MoralParam;
  epoch?: EpochParam;
  writingStyle?: WritingStyleParam;
  elements?: TagParam;
  storyLength?: StoryLength;
  narrator?: PredefinedNarrator;
  other?: OtherParameter;
  templateId?: string;
  templateStoryId?: string;
  selectedTileId?: string;
}

const initialState: StoryCreatorState = {
  storyLength: StoryLength.SHORT,
  world: { random: true },
  tempWorld: { random: true },
  moral: { random: true },
  tempMoral: { random: true },
  epoch: { random: true },
  writingStyle: { random: true },
  elements: { random: true }
};

export const storyCreatorSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setRecipient(state, action: PayloadAction<string | undefined>) {
      state.recipient = action.payload;
    },
    setTempRecipient(state, action: PayloadAction<string | undefined>) {
      state.tempRecipient = action.payload;
    },
    setMainHero(state, action: PayloadAction<HeroParam | undefined>) {
      state.mainHero = action.payload;
    },
    setTempMainHero(state, action: PayloadAction<HeroParam | undefined>) {
      state.tempMainHero = action.payload;
    },
    setSideHero(state, action: PayloadAction<HeroParam | undefined>) {
      state.sideHero = action.payload;
    },
    setTempSideHero(state, action: PayloadAction<HeroParam | undefined>) {
      state.tempSideHero = action.payload;
    },
    setWorld(state, action: PayloadAction<WorldParam | undefined>) {
      state.world = action.payload;
    },
    setTempWorld(state, action: PayloadAction<WorldParam | undefined>) {
      state.tempWorld = action.payload;
    },
    setMoral(state, action: PayloadAction<MoralParam | undefined>) {
      state.moral = action.payload;
    },
    setTempMoral(state, action: PayloadAction<MoralParam | undefined>) {
      state.tempMoral = action.payload;
    },
    setEpoch(state, action: PayloadAction<EpochParam | undefined>) {
      state.epoch = action.payload;
    },
    setWritingStyle(state, action: PayloadAction<WritingStyleParam | undefined>) {
      state.writingStyle = action.payload;
    },
    setElements(state, action: PayloadAction<TagParam | undefined>) {
      state.elements = action.payload;
    },
    setStoryLength(state, action: PayloadAction<StoryLength>) {
      state.storyLength = action.payload;
    },
    setOther(state, action: PayloadAction<OtherParameter>) {
      state.other = action.payload;
    },
    setNarrator(state, action: PayloadAction<PredefinedNarrator>) {
      state.narrator = action.payload;
    },
    setTemplateId(state, action: PayloadAction<string>) {
      state.templateId = action.payload;
    },
    setTemplateStoryId(state, action: PayloadAction<string>) {
      state.templateStoryId = action.payload;
    },
    setSelectedTileId(state, action: PayloadAction<string>) {
      state.selectedTileId = action.payload;
    },
    resetCreatorState() {
      return initialState;
    }
  }
});

export const {
  setRecipient,
  setTempRecipient,
  setMainHero,
  setTempMainHero,
  setSideHero,
  setTempSideHero,
  setWorld,
  setTempWorld,
  setMoral,
  setTempMoral,
  setEpoch,
  setWritingStyle,
  setElements,
  setStoryLength,
  setOther,
  setNarrator,
  setTemplateId,
  setTemplateStoryId,
  setSelectedTileId,
  resetCreatorState
} = storyCreatorSlice.actions;

export default storyCreatorSlice.reducer;
