import { Typography } from 'features/ui/typography/typography';
import { router } from 'routing/routes';
import paths from 'routing/utils';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setSelectedTileId, setTempMoral } from 'store/slices/storyCreatorSlice';
import { ElementTile } from '../elementTile';
import { TileProps } from './types.elementTiles';

export const MoralTile = (props: TileProps) => {
  const loggedUser = useAppSelector(state => state.authSlice.loggedUser);
  const storyCreatorSlice = useAppSelector(state => state.storyCreatorSlice);
  const dispatch = useAppDispatch();

  const onAction = () => {
    dispatch(setSelectedTileId(props.tileId));
    dispatch(setTempMoral(undefined));
    router.navigate(paths.storyCreator.custom.moral.base);
  };

  const getImageAndDir = () => {
    const moral = storyCreatorSlice.moral?.moral;
    const isMoralPicked = Boolean(moral);
    const doesMoralHaveImage = Boolean(moral?.image);
    const isMoralPredefined = Boolean(moral?.predefined);
    const moralImage = moral?.image;

    if (isMoralPicked) {
      if (doesMoralHaveImage) {
        return {
          image: moralImage,
          dir: isMoralPredefined ? 'common' : loggedUser?.id
        };
      }
      return { image: 'story_moral.png', dir: 'common' };
    }
    return { image: 'random.png', dir: 'common' };
  };

  const { image, dir } = getImageAndDir();

  return (
    <ElementTile tileId={props.tileId} action={onAction} filename={image} directory={dir}>
      <>
        <Typography variant="h4">Morał</Typography>
        <Typography variant="description">{storyCreatorSlice.moral?.moral?.value ?? 'Brak konkretnego morału'}</Typography>
      </>
    </ElementTile>
  );
};
