import { RouteLayout } from 'features/ui/layout/routeLayout';
import { Typography } from 'features/ui/typography/typography';
import { useEffect, useState } from 'react';
import { router } from 'routing/routes';
import paths from 'routing/utils';
import { PredefinedNarrator } from 'shared/types/narrator';
import { useGetPredefinedNarratorsQuery } from 'store/api/endpoints/predefinedNarratorsEndpoints';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setNarrator } from 'store/slices/storyCreatorSlice';
import { PickerItemPreview } from '../pickerItemPreview';
import _ from 'lodash';

const defaultPredefinedNarrators: PredefinedNarrator[] = [];

type Props = {
  prevRoute: string;
};

export const NarratorPicker = (props: Props) => {
  const [selectedNarrator, setSelectedNarrator] = useState<PredefinedNarrator>();
  // redux
  const dispatch = useAppDispatch();
  const narrator = useAppSelector(state => state.storyCreatorSlice.narrator);
  // rtk
  const { data: predefinedNarrators = defaultPredefinedNarrators } = useGetPredefinedNarratorsQuery();

  useEffect(() => {
    setSelectedNarrator(narrator);
  }, [narrator]);

  const templateMode = props.prevRoute === paths.storyCreator.template.base;

  const pathPostfix = templateMode ? 'template' : 'custom';

  const backRoute = paths.storyCreator[pathPostfix].base;

  return (
    <RouteLayout
      backRoute={backRoute}
      bottomActions={[
        {
          label: 'Zapisz',
          action: () => {
            if (selectedNarrator) {
              dispatch(setNarrator(selectedNarrator));
            }
            router.navigate(backRoute);
          }
        }
      ]}
    >
      <div className="pt-4 mx-3">
        <Typography variant="h1">Wybierz Lektora</Typography>
        <div className="d-flex justify-content-center align-items-start gap-3 mt-5 flex-wrap">
          {_.sortBy(predefinedNarrators, 'order').map(pn => (
            <div key={pn.id}>
              <PickerItemPreview
                isSelected={selectedNarrator?.id === pn.id}
                filename={pn.image}
                directory={'common'}
                name={pn.value}
                onClickCallback={() => {
                  setSelectedNarrator(pn);
                }}
              />
            </div>
          ))}
        </div>
      </div>
    </RouteLayout>
  );
};
