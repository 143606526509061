import { Divider } from 'features/ui/divider/divider';
import { CustomImage } from 'features/ui/image/customImage';
import { RouteLayout } from 'features/ui/layout/routeLayout';
import { Typography } from 'features/ui/typography/typography';
import React from 'react';
import { pxToRem } from 'shared/utils/commonUtils';
import { ChildRibbon } from './child-profile/childProfileRibbon';
import { HeroRibbon } from './hero-profile/heroRibbon';
import { bottomMenuOptions } from './userProfileMenu';

export const UserProfile = () => {
  return (
    <RouteLayout>
      <div className="my-5 mx-3" style={{ position: 'relative' }}>
        <CustomImage
          directory="common"
          filename="spiral.png"
          styles={{ position: 'absolute', zIndex: -1, right: 0, top: 0, opacity: 0.4 }}
        />

        <Typography variant="h1">Profil</Typography>
        <div className="mt-5">
          <ChildRibbon />
        </div>
        <div className="mt-5">
          <HeroRibbon />
        </div>
        <div className="mt-5">
          {bottomMenuOptions.map((btn, index) => (
            <React.Fragment key={index}>
              <div
                className="d-flex align-items-center justify-content-between gap-2 py-3"
                style={{ borderLeft: ' none', borderRight: 'none', padding: '0 0 0 4px' }}
                role="button"
                onClick={() => btn.action()}
              >
                <div className="d-flex align-items-center gap-2">
                  {btn.icon}
                  <Typography variant="description">{btn.label}</Typography>
                </div>

                <i style={{ fontSize: pxToRem(18) }} className="bi bi-chevron-right"></i>
              </div>
              {index !== bottomMenuOptions.length - 1 && <Divider spacing="my-1" />}
            </React.Fragment>
          ))}
        </div>
      </div>
    </RouteLayout>
  );
};
