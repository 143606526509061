import { CustomImage } from 'features/ui/image/customImage';
import { RouteLayout } from 'features/ui/layout/routeLayout';
import { Typography } from 'features/ui/typography/typography';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { router } from 'routing/routes';
import paths from 'routing/utils';
import { WhiteCloud } from 'shared/images/whiteCloud';
import { PredefinedNarrator } from 'shared/types/narrator';
import { CustomStoryParams } from 'shared/types/story';
import { cPrimary } from 'shared/utils/styleCommon';
import { useGetLoggedUserQuery } from 'store/api/endpoints/accountEndpoints';
import { useGenerateCustomStoryMutation } from 'store/api/endpoints/generatorEndpoints';
import { useGetPredefinedNarratorsQuery } from 'store/api/endpoints/predefinedNarratorsEndpoints';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { addAlert } from 'store/slices/alertsSlice';
import { resetCreatorState, setNarrator } from 'store/slices/storyCreatorSlice';
import { resetStoryGeneratorState, setIsGenerating } from 'store/slices/storyGeneratorSlice';
import { ElementsTile } from './element-tiles/elementsTile';
import { EpochTile } from './element-tiles/epochTile';
import { HeroesTile } from './element-tiles/heroesTile';
import { MoralTile } from './element-tiles/moralTile';
import { NarratorTile } from './element-tiles/narratorTile';
import { RecipientTile } from './element-tiles/recipientTile';
import { StoryLengthTile } from './element-tiles/storyLenghtTile';
import { WorldTile } from './element-tiles/worldTile';
import { WritingStyleTile } from './element-tiles/writingStyleTile';
import { StoryCreatorLoader } from './loader/storyCreatorLoader';

const defaultPredefinedNarrators: PredefinedNarrator[] = [];

export const CustomStoryCreator = () => {
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState<boolean>(false);
  // redux
  const storyCreatorSlice = useAppSelector(state => state.storyCreatorSlice);
  const generatedStoryId = useAppSelector(state => state.storyGeneratorSlice.generatedStoryId);
  const isGenerating = useAppSelector(state => state.storyGeneratorSlice.isGenerating);
  const narrator = useAppSelector(state => state.storyCreatorSlice.narrator);
  const selectedTileId = useAppSelector(state => state.storyCreatorSlice.selectedTileId);
  const dispatch = useAppDispatch();
  // rtk
  const { refetch: refetchUser } = useGetLoggedUserQuery();
  const { data: predefinedNarrators = defaultPredefinedNarrators } = useGetPredefinedNarratorsQuery();
  const [generate] = useGenerateCustomStoryMutation();

  useEffect(() => {
    if (!narrator) {
      const freeNarrator = predefinedNarrators.find(pn => pn.cost === 0);
      freeNarrator && dispatch(setNarrator(freeNarrator));
    }
  }, [dispatch, narrator, predefinedNarrators]);

  useEffect(() => {
    if (!_.isNil(generatedStoryId)) {
      dispatch(resetStoryGeneratorState());
      dispatch(resetCreatorState());
      refetchUser();
      router.navigate(paths.story + `/${generatedStoryId}`, { state: 'FROM_GENERATOR' });
    }
  }, [dispatch, generatedStoryId, refetchUser]);

  const generateCustomStory = () => {
    dispatch(setIsGenerating(true));

    if (!storyCreatorSlice.narrator) {
      dispatch(addAlert({ color: 'warning', text: 'Lektor nie został wybrany' }));
      return;
    }

    const body: CustomStoryParams = {
      narrator: storyCreatorSlice.narrator,
      recipient: storyCreatorSlice.recipient,
      mainHero: storyCreatorSlice.mainHero,
      sideHero: storyCreatorSlice.sideHero,
      world: storyCreatorSlice.world,
      moral: storyCreatorSlice.moral,
      epoch: storyCreatorSlice.epoch,
      tags: storyCreatorSlice.elements,
      writingStyle: storyCreatorSlice.writingStyle,
      storyLength: storyCreatorSlice.storyLength
    };

    generate(body)
      .unwrap()
      .catch(() => {
        dispatch(setIsGenerating(false));
        dispatch(addAlert({ color: 'danger', text: 'Nie udało się utworzyć bajki' }));
      });
  };

  return isGenerating ? (
    <StoryCreatorLoader />
  ) : (
    <RouteLayout
      bottomActions={[
        {
          label: 'Stwórz bajkę',
          action: () => {
            let points = 0;
            points += storyCreatorSlice.recipient ? 1 : 0;
            points += storyCreatorSlice.mainHero?.character ? 1 : 0;
            points += storyCreatorSlice.sideHero?.character ? 1 : 0;
            points += storyCreatorSlice.elements?.predefinedTags ? 1 : 0;
            points += storyCreatorSlice.world?.world ? 1 : 0;
            points += storyCreatorSlice.moral?.moral ? 1 : 0;
            points += storyCreatorSlice.epoch?.predefinedEpoch ? 1 : 0;
            points += storyCreatorSlice.writingStyle?.predefinedWritingStyle ? 1 : 0;
            points >= 3 ? generateCustomStory() : setIsConfirmationDialogOpen(true);
          }
        }
      ]}
    >
      <Modal centered show={isConfirmationDialogOpen} onHide={() => setIsConfirmationDialogOpen(false)}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="p-4">
          <Typography variant="h1" classNames="pb-3">
            Dopasuj bajkę
          </Typography>
          <Typography classNames="pb-2" variant="description">
            Sugerujemy wprowadzenie przynajmniej:
          </Typography>
          <Typography classNames="pb-1" variant="description">
            - Dla kogo
          </Typography>
          <Typography classNames="pb-2" variant="description">
            - Bohaterów
          </Typography>
          <Typography classNames="pb-5" variant="description">
            Pamiętaj, im więcej powiesz o swoich preferencjach tym bardziej dopasowaną bajkę stworzysz.
          </Typography>
          <div className="d-flex flex-column align-items-center justify-content-center gap-3">
            <Button className="fullWidth" variant="primary" onClick={generateCustomStory}>
              Zaskocz mnie
            </Button>
            <Button className="fullWidth" variant="outline-primary" onClick={() => setIsConfirmationDialogOpen(false)}>
              Dopasuj bajkę
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      <div style={{ position: 'relative' }}>
        <CustomImage
          directory="common"
          filename="create-story.png"
          width="100%"
          // TODO Safari bug
          // onLoad={() => {
          //   setTimeout(() => selectedTileId && document.getElementById(selectedTileId)?.scrollIntoView({ behavior: 'instant' }), 0);
          // }}
        />
        <WhiteCloud />
      </div>
      <div className="mx-3">
        <Typography variant="h1" styles={{ paddingBottom: '40px' }}>
          {/* exact number 40px was given by business in this case */}
          Twoja Bajka
        </Typography>
        <div className="d-flex flex-column gap-2">
          <RecipientTile customCreator tileId="RecipientTile" />

          <div className="w-100 my-2" style={{ borderBottom: `2px solid ${cPrimary}` }} />

          <HeroesTile customCreator tileId="HeroesTile" />
          <NarratorTile customCreator tileId="NarratorTile" />
          <WorldTile tileId="WorldTile" />
          <MoralTile tileId="MoralTile" />
          <ElementsTile tileId="ElementsTile" />
          <WritingStyleTile tileId="WritingStyleTile" />
          <EpochTile tileId="EpochTile" />
          <StoryLengthTile tileId="StoryLengthTile" />
        </div>
      </div>
    </RouteLayout>
  );
};
