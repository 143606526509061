import { RouteLayout } from 'features/ui/layout/routeLayout';
import { Typography } from 'features/ui/typography/typography';
import { useEffect, useState } from 'react';
import { router } from 'routing/routes';
import paths from 'routing/utils';
import { QuestionMarkIcon } from 'shared/icons/questionMarkIcon';
import { PredefinedEpoch } from 'shared/types/epoch';
import { cGrey200, cPrimary, cSecondary } from 'shared/utils/styleCommon';
import { useGetPredefinedEpochsQuery } from 'store/api/endpoints/predefinedEpochsEndpoints';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { EpochParam, setEpoch } from 'store/slices/storyCreatorSlice';
import { PickerItemPreview } from '../pickerItemPreview';

const defaultPredefinedEpochs: PredefinedEpoch[] = [];

export const EpochPicker = () => {
  const [selectedEpoch, setSelectedEpoch] = useState<EpochParam>();
  // redux
  const dispatch = useAppDispatch();
  const epoch = useAppSelector(state => state.storyCreatorSlice.epoch);
  // rtk
  const { data: predefinedEpochs = defaultPredefinedEpochs } = useGetPredefinedEpochsQuery();

  useEffect(() => {
    setSelectedEpoch(epoch);
  }, [epoch]);

  return (
    <RouteLayout
      backRoute={paths.storyCreator.custom.base}
      bottomActions={[
        {
          label: 'Zapisz',
          action: () => {
            dispatch(setEpoch(selectedEpoch));
            router.navigate(paths.storyCreator.custom.base);
          }
        }
      ]}
    >
      <div className="pt-4 mx-3">
        <Typography variant="h1">Wybierz czas</Typography>
        <div className="d-flex justify-content-center align-items-start gap-3 mt-5 flex-wrap">
          <div role="button" className="d-flex flex-column justify-content-start" onClick={() => setSelectedEpoch({ random: true })}>
            <div
              style={{
                outline: selectedEpoch?.random ? `2px solid ${cPrimary}` : `2px solid ${cGrey200}`,
                borderRadius: '10px',
                width: '160px',
                height: '166px'
              }}
              className="d-flex justify-content-center align-items-center"
            >
              <QuestionMarkIcon fill={selectedEpoch?.random ? cPrimary : '#112B52'} />
            </div>
            <Typography variant="description" classNames="pt-2" styles={{ color: selectedEpoch?.random ? cPrimary : cSecondary }}>
              Losowy
            </Typography>
          </div>
          {predefinedEpochs.map(pe => (
            <div key={pe.id}>
              <PickerItemPreview
                isSelected={selectedEpoch?.predefinedEpoch?.id === pe.id}
                filename={pe.image}
                directory={'common'}
                name={pe.value}
                onClickCallback={() => {
                  setSelectedEpoch({ random: false, predefinedEpoch: pe });
                }}
              />
            </div>
          ))}
        </div>
      </div>
    </RouteLayout>
  );
};
