import { Col, Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { ChevronLeftIcon } from 'shared/icons/chevronLeftIcon';
import { cGrey200 } from 'shared/utils/styleCommon';
import { Typography } from '../typography/typography';

export interface TopNavigationAction {
  icon?: JSX.Element;
  label?: string;
  action: () => void;
}

type Props = {
  actions?: TopNavigationAction[];
  backRoute?: string | -1;
  backRouteState?: any;
  backRouteAdditionalAction?: () => void;
};

export const TopNavigation = (props: Props) => {
  const navigate = useNavigate();

  const goBack = () => {
    if (props.backRoute) {
      if (props.backRoute === -1) {
        props.backRouteAdditionalAction?.();
        navigate(-1);
      } else {
        props.backRouteAdditionalAction?.();
        navigate(props.backRoute, props.backRouteState ? { state: props.backRouteState } : {});
      }
    }
  };

  return (
    <Container style={{ maxWidth: 'none', borderBottom: `1px solid ${cGrey200}` }}>
      <Row className="justify-content-sm-center h-100">
        <Col xxl={6} xl={6} lg={6} md={6} sm={8} xs={12} style={{ padding: 0 }}>
          <div className="d-flex justify-content-between align-items-center p-4 ">
            <div className="d-flex align-items-center gap-4" style={{ width: 'fit-content' }} role="button" onClick={goBack}>
              <ChevronLeftIcon />
              <Typography variant="h4">Cofnij</Typography>
            </div>

            <div className="d-flex align-items-center gap-4">
              {props.actions &&
                props.actions.map((a, idx) => (
                  <div
                    className="d-flex align-items-center gap-4"
                    role="button"
                    style={{ width: 'fit-content' }}
                    onClick={a.action}
                    key={idx}
                  >
                    {a.icon ? a.icon : ''}
                    {a.label ? <Typography variant="h4">{a.label}</Typography> : <></>}
                  </div>
                ))}
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
