import { FormError } from 'features/ui/form/formError';
import { RouteLayout } from 'features/ui/layout/routeLayout';
import { Typography } from 'features/ui/typography/typography';
import { Form } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { router } from 'routing/routes';
import paths from 'routing/utils';
import { validateEmail, validationMessage } from 'shared/utils/formUtils';
import { useUpdateAccountMutation } from 'store/api/endpoints/accountEndpoints';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { addAlert } from 'store/slices/alertsSlice';

export const EmailSettings = () => {
  // redux
  const loggedUser = useAppSelector(state => state.authSlice.loggedUser);
  const dispatch = useAppDispatch();
  // rtk
  const [update] = useUpdateAccountMutation();

  const {
    control,
    formState: { isValid, isDirty },
    handleSubmit
  } = useForm<{ email: string }>({
    mode: 'onChange',
    defaultValues: {
      email: loggedUser?.email ?? ''
    }
  });

  const onSubmit = (data: { email: string }) => {
    if (loggedUser) {
      update({
        id: loggedUser.id,
        firstName: loggedUser.firstName,
        lastName: loggedUser.lastName,
        email: data.email,
        playbackRate: loggedUser.playbackRate
      })
        .unwrap()
        .then(() => {
          router.navigate(paths.logout);
        })
        .catch(() => dispatch(addAlert({ color: 'danger', text: 'Nie udało się zaktualizować danych użytkownika.' })));
    }
  };

  return (
    <RouteLayout
      backRoute={-1}
      bottomActions={[
        {
          label: 'Zapisz',
          disabled: !isValid || !isDirty,
          submittable: true,
          formId: 'resetEmailForm'
        }
      ]}
    >
      <Form onSubmit={handleSubmit(onSubmit)} id="resetEmailForm" noValidate className="mx-3 pt-5">
        <Form.Group className="mb-3" controlId="resetEmailForm.email">
          <Form.Label>E-mail</Form.Label>
          <Controller
            name="email"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <>
                  <Form.Control {...field} type="email" placeholder="E-mail" isInvalid={fieldState.invalid} required />
                  {fieldState.invalid ? <FormError error={fieldState.error?.message} /> : ''}
                </>
              );
            }}
            rules={{
              required: validationMessage.required,
              validate: validateEmail
            }}
          />
        </Form.Group>
        <Typography variant="description">
          Zmiana adresu email spowoduje wylogowanie się. Będziesz mógł się zalogować na nowy email.
        </Typography>
      </Form>
    </RouteLayout>
  );
};
