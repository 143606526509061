import { FormError } from 'features/ui/form/formError';
import { JustifyCenter } from 'features/ui/layout/justifyCenter';
import { RouteLayout } from 'features/ui/layout/routeLayout';
import { Loadable } from 'features/ui/loadable/loadable';
import { Typography } from 'features/ui/typography/typography';
import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/esm/Button';
import Form from 'react-bootstrap/esm/Form';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import paths from 'routing/utils';
import { pxToRem } from 'shared/utils/commonUtils';
import { validateEmail, validationMessage } from 'shared/utils/formUtils';
import { cSecondary } from 'shared/utils/styleCommon';
import { API_URL } from 'store/api/api';
import { useSignInMutation } from 'store/api/endpoints/accountEndpoints';
import { useIsGeneratingQuery } from 'store/api/endpoints/generatorEndpoints';
import { useAppSelector } from 'store/hooks';

interface SignInFormValues {
  email: string;
  password: string;
  rememberMe: boolean;
}

export const SignIn = (): JSX.Element => {
  const [loginError, setLoginError] = useState('');
  // redux
  const loggedUser = useAppSelector(state => state.authSlice.loggedUser);
  // rtk
  const [signIn, { error, isLoading }] = useSignInMutation();
  const { refetch: refetchIsGenerating } = useIsGeneratingQuery();
  // other
  const navigate = useNavigate();

  const { control, handleSubmit } = useForm<SignInFormValues>({
    mode: 'onBlur',
    defaultValues: {
      email: '',
      password: '',
      rememberMe: false
    }
  });

  useEffect(() => {
    if (!error) {
      setLoginError('');
      return;
    }
    if ('status' in error && error.status === 401) {
      setLoginError('Niepoprawne dane logowania');
    } else {
      setLoginError('Wystąpił błąd podczas logowania');
    }
  }, [error]);

  useEffect(() => {
    if (loggedUser) {
      navigate(paths.root, { replace: true });
      refetchIsGenerating();
    }
  }, [loggedUser, navigate, refetchIsGenerating]);

  const onSubmit = (data: SignInFormValues) => {
    signIn({ username: data.email.toLowerCase(), password: data.password, rememberMe: data.rememberMe });
  };

  const getSocialLoginUrl = (provider: string) => {
    window.open(
      `${API_URL}/oauth2/authorization/${provider}?redirect_uri=${process.env.REACT_APP_OAUTH2_REDIRECT_URL}`,
      '_blank',
      'noreferrer'
    );
  };

  return (
    <RouteLayout>
      <Loadable loading={isLoading}>
        <div className="d-flex flex-column h-100 justify-content-between py-5 mx-3">
          <div>
            <Typography variant="h1">Zaloguj się</Typography>
            <div className="py-4">{loginError ? <FormError error={loginError} /> : ''}</div>
            <Form onSubmit={handleSubmit(onSubmit)} noValidate>
              <Form.Group className="mb-3" controlId="signInForm.email">
                <Form.Label>E-mail</Form.Label>
                <Controller
                  name="email"
                  control={control}
                  render={({ field, fieldState }) => (
                    <>
                      <Form.Control {...field} type="email" placeholder="E-mail" isInvalid={fieldState.invalid} />
                      {fieldState.invalid ? <FormError error={fieldState.error?.message} /> : ''}
                    </>
                  )}
                  rules={{
                    required: validationMessage.required,
                    validate: validateEmail
                  }}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="signInForm.password">
                <Form.Label>Hasło</Form.Label>
                <Controller
                  name="password"
                  control={control}
                  render={({ field, fieldState }) => (
                    <>
                      <Form.Control {...field} type="password" placeholder="Hasło" isInvalid={fieldState.invalid} />
                      {fieldState.invalid ? <FormError error={fieldState.error?.message} /> : ''}
                    </>
                  )}
                  rules={{
                    required: validationMessage.required
                  }}
                />
              </Form.Group>
              <Form.Group className="mb-3 d-flex align-items-center gap-2" controlId="signInForm.rememberMe">
                <Controller
                  name="rememberMe"
                  control={control}
                  render={({ field }) => (
                    <Form.Check
                      {...field}
                      value={''}
                      checked={field.value}
                      onChange={e => field.onChange(e.target.checked)}
                      type="checkbox"
                      inline
                      label="Zapamiętaj mnie"
                    />
                  )}
                />
              </Form.Group>

              <div className="d-flex justify-content-center align-items-center flex-wrap">
                <Typography variant="description">Zapomniałeś hasło?</Typography>
                <Button
                  variant="link"
                  onClick={() => navigate(paths.resetPassword)}
                  style={{ fontSize: pxToRem(18), color: cSecondary, textDecoration: 'none', padding: 4, fontWeight: '800' }}
                >
                  Przypomnij hasło
                </Button>
              </div>

              <JustifyCenter classNames="pt-5">
                <Button className={'long'} type="submit" disabled={isLoading}>
                  Zaloguj się
                </Button>
              </JustifyCenter>
            </Form>

            {/* TODO wait until google client id and secret are delivered by business for prod */}
            {/* <JustifyCenter classNames="pt-5">
              <Button className={'long d-flex align-items-center gap-2'} onClick={() => getSocialLoginUrl('google')} disabled={isLoading}>
                <GoogleIcon />
                Zaloguj z Google
              </Button>
            </JustifyCenter> */}
          </div>

          <div className="d-flex flex-column gap-2 pt-4">
            <JustifyCenter>
              <Typography variant="description-xs">Nie masz jeszcze konta?</Typography>
            </JustifyCenter>
            <JustifyCenter>
              <Button className={'long'} variant="outline-primary" onClick={() => navigate(paths.signUp.base)}>
                Zarejestruj się
              </Button>
            </JustifyCenter>
          </div>
        </div>
      </Loadable>
    </RouteLayout>
  );
};
