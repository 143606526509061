import { skipToken } from '@reduxjs/toolkit/query';
import { Divider } from 'features/ui/divider/divider';
import { CustomImage } from 'features/ui/image/customImage';
import { RouteLayout } from 'features/ui/layout/routeLayout';
import { HorizontalStoryPreview } from 'features/ui/story-preview/horizontalStoryPreview';
import { Typography } from 'features/ui/typography/typography';
import _ from 'lodash';
import { useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { router } from 'routing/routes';
import paths from 'routing/utils';
import { convertToAccessLevel, convertToSubscriptionType, hasAccess } from 'shared/hooks/useHasSufficientSub';
import { WhiteCloud } from 'shared/images/whiteCloud';
import { Creator } from 'shared/types/story';
import { SubscriptionType } from 'shared/types/users';
import { useGetSeriesByIdQuery } from 'store/api/endpoints/seriesEndpoints';
import { Operator, SearchOperation, useSearchStoriesMutation } from 'store/api/endpoints/storyEndpoints';
import { useAppSelector } from 'store/hooks';

export const SeriesRenderer = () => {
  // redux
  const userSubscriptionType = useAppSelector(state => state.authSlice.loggedUser)?.subscription?.type;
  // rtk
  let { seriesId } = useParams();
  const { data: series } = useGetSeriesByIdQuery(seriesId ?? skipToken);
  const [searchStory, { data: stories }] = useSearchStoriesMutation();

  const isTemplateSeries = series?.creator === Creator.SYSTEM;

  useEffect(() => {
    if (series) {
      searchStory({
        page: 0,
        size: series.episodes.length,
        searchRequest: {
          operator: Operator.AND,
          searchCriteriaGroups: [
            {
              groupOperator: Operator.AND,
              searchCriteria: [
                {
                  filterKey: 'id',
                  values: series.episodes.map(e => e.episodeId),
                  operation: SearchOperation.INCLUDES
                }
              ]
            }
          ]
        }
      });
    }
  }, [series, searchStory]);

  const isNotLoggedIn = _.isNil(userSubscriptionType);
  const highestSubscriptionTypeInSeries = convertToSubscriptionType(
    _.max(stories?.content?.map(s => convertToAccessLevel(s.subscriptionType)))
  );
  const hasAccessToAll = hasAccess(highestSubscriptionTypeInSeries, userSubscriptionType);

  return (
    <RouteLayout
      backRoute={-1}
      bottomElement={
        <div className="mx-3">
          {isNotLoggedIn && highestSubscriptionTypeInSeries !== SubscriptionType.FREE && (
            <div className="text-center d-flex flex-column align-items-center gap-3 pt-3 pb-3">
              <Typography variant="h1">Zarejestruj się i zobacz więcej odcinków!</Typography>
              <Typography variant="description">Odkryj wszystkie nasze niesamowite funkcje i korzyści!</Typography>
              <Button onClick={() => router.navigate(paths.signUp.base)}>Zarejestruj się</Button>
            </div>
          )}

          {!isNotLoggedIn && !hasAccessToAll && (
            <div className="text-center d-flex flex-column align-items-center gap-3 pt-3 pb-3">
              <Typography variant="h1">Odblokuj wyższą wersję i zobacz więcej odcinków!</Typography>
              <Typography variant="description">Odkryj wszystkie nasze niesamowite funkcje i korzyści!</Typography>
              <Button onClick={() => router.navigate(paths.subscription.base + `/${highestSubscriptionTypeInSeries}`)}>Odblokuj</Button>
            </div>
          )}
        </div>
      }
    >
      <div style={{ position: 'relative' }}>
        <CustomImage directory={isTemplateSeries ? 'common' : series?.ownerId} filename={series?.image} width="100%" />
        <WhiteCloud />
      </div>

      <div className="mx-3">
        <div className="pt-4">
          <Typography variant="h1">{series?.title}</Typography>
        </div>
        <Divider spacing="my-4" />

        <div className="d-flex flex-column pb-3">
          {stories?.content
            ?.slice()
            ?.sort((a, b) => {
              const episodeA = series?.episodes.find(e => e.episodeId === a.storyId)?.index;
              const episodeB = series?.episodes.find(e => e.episodeId === b.storyId)?.index;

              if (episodeA === undefined) return 1;
              if (episodeB === undefined) return -1;

              return parseInt(episodeA) - parseInt(episodeB);
            })
            ?.map((s, idx) => (
              <div key={s.storyId}>
                <HorizontalStoryPreview story={s} />
                {idx !== stories?.content.length - 1 && <Divider spacing="my-4" />}
              </div>
            ))}
        </div>
      </div>
    </RouteLayout>
  );
};
