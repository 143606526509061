import { CSSProperties } from 'react';
import { pxToRem } from 'shared/utils/commonUtils';
import { cSecondary } from 'shared/utils/styleCommon';
import styled from 'styled-components';

const StyledTypography = styled.div`
  color: ${cSecondary};
  &.typography-h1 {
    font-size: ${pxToRem(28)};
    font-weight: 800;
  }

  &.typography-h4 {
    font-size: ${pxToRem(18)};
    font-weight: 800;
  }

  &.typography-h5 {
    font-size: ${pxToRem(16)};
    font-weight: 800;
  }

  &.typography-description {
    font-size: ${pxToRem(18)};
    font-weight: 400;
  }

  &.typography-description-md {
    font-size: ${pxToRem(16)};
    font-weight: 400;
  }

  &.typography-description-sm {
    font-size: ${pxToRem(14)};
    font-weight: 400;
  }

  &.typography-description-sm-bold {
    font-size: ${pxToRem(14)};
    font-weight: 800;
  }

  &.typography-description-xs {
    font-size: ${pxToRem(12)};
    font-weight: 400;
  }

  &.typography-description-xxs {
    font-size: ${pxToRem(10)};
    font-weight: 400;
  }
`;

type Props = {
  variant?:
    | 'h1'
    | 'h4'
    | 'h5'
    | 'description'
    | 'description-md'
    | 'description-sm'
    | 'description-sm-bold'
    | 'description-xs'
    | 'description-xxs';
  classNames?: string;
  styles?: CSSProperties;
  inline?: boolean;
  children: React.ReactNode;
};

export const Typography = ({ variant = 'description', classNames, styles, inline, children }: Props) => (
  <StyledTypography style={{ ...styles, display: inline ? 'inline' : 'block' }} className={`typography-${variant} ${classNames}`}>
    {children}
  </StyledTypography>
);
