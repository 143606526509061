import DOMPurify from 'dompurify';
import { RouteLayout } from 'features/ui/layout/routeLayout';
import { Typography } from 'features/ui/typography/typography';
import { useLocation } from 'react-router-dom';
import { Storytailo } from 'shared/images/storytailo';
import { cBlue100, cWhite } from 'shared/utils/styleCommon';
import { useGetTermsQuery } from 'store/api/endpoints/termsEndpoints';
import blueSpiral from '../../shared/images/blue-spiral.svg';

type Props = {
  backRoute: string;
};

export const Terms = (props: Props) => {
  // rtk
  const { data: terms } = useGetTermsQuery();
  // other
  const location = useLocation();

  const dirtyHTML = terms ? terms.content : '';

  const sanitizedHTML = DOMPurify.sanitize(dirtyHTML, {
    RETURN_TRUSTED_TYPE: true,
    FORCE_BODY: true,
    ALLOWED_TAGS: ['html', 'head', 'style', 'body', 'p', 'span', 'div', 'br', 'ul', 'li', 'ol'],
    ADD_ATTR: ['class', 'id']
  });

  return (
    <RouteLayout backRoute={props.backRoute} backRouteState={location.state}>
      <div className="mx-3 pt-5">
        <Typography variant="h1" classNames="pb-4">
          Regulamin serwisu
        </Typography>
        <div className="d-flex flex-column w-100  pb-5 gap-3">
          <Typography variant="description">
            <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }}></div>
          </Typography>
        </div>
      </div>
      <div
        className="d-flex flex-column align-items-center justify-content-center gap-3 py-4 w-100"
        style={{ height: '268px', backgroundColor: cBlue100, position: 'relative' }}
      >
        <img src={blueSpiral} width={220} height={200} alt="" style={{ position: 'absolute', zIndex: 1, opacity: 0.5, rotate: '5deg' }} />
        <Typography variant="description" classNames="text-center" styles={{ color: cWhite, zIndex: 2 }}>
          Masz pytania? - napisz. komunikacja@storytailo.com
        </Typography>
        <div style={{ zIndex: 2 }}>
          <Storytailo />
        </div>
        <Typography variant="description" classNames="text-center" styles={{ color: cWhite, zIndex: 2 }}>
          Copyright © Creanso Sp. z o.o. Wszystkie prawa zastrzeżone.
        </Typography>
      </div>
    </RouteLayout>
  );
};
