import { FormError } from 'features/ui/form/formError';
import { JustifyCenter } from 'features/ui/layout/justifyCenter';
import { RouteLayout } from 'features/ui/layout/routeLayout';
import { Loadable } from 'features/ui/loadable/loadable';
import { Typography } from 'features/ui/typography/typography';
import { useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import Form from 'react-bootstrap/esm/Form';
import ReCAPTCHA from 'react-google-recaptcha';
import { Controller, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import paths from 'routing/utils';
import { pxToRem } from 'shared/utils/commonUtils';
import { validateEmail, validatePassword, validationMessage } from 'shared/utils/formUtils';
import { cGrey600, cSecondary } from 'shared/utils/styleCommon';
import { useRegisterMutation } from 'store/api/endpoints/accountEndpoints';
import { useValidateRecaptchaTokenMutation } from 'store/api/endpoints/recaptchaEndpoints';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { addAlert } from 'store/slices/alertsSlice';
import { PasswordRules } from './passwordRules';

interface SignUpFormValues {
  email: string;
  password: string;
  firstName: string;
  repeatPassword: string;
  termsAccepted: boolean;
}

export const SignUp = (): JSX.Element => {
  const [highlightRules, setHighlightRules] = useState(false);
  const captchaRef = useRef<any>(null);
  // redux
  const dispatch = useAppDispatch();
  const loggedUser = useAppSelector(state => state.authSlice.loggedUser);
  // rtk
  const [register, { isSuccess, isError, isLoading }] = useRegisterMutation();
  const [validateRecaptchaToken] = useValidateRecaptchaTokenMutation();
  // other
  const navigate = useNavigate();
  const location = useLocation();

  const {
    control,
    formState,
    reset: resetForm,
    handleSubmit,
    getValues,
    watch
  } = useForm<SignUpFormValues>({
    mode: 'onBlur',
    defaultValues: {
      email: '',
      password: '',
      repeatPassword: '',
      firstName: '',
      termsAccepted: false
    }
  });

  useEffect(() => {
    if (location.state) {
      resetForm({
        email: location.state?.email ?? '',
        password: location.state?.password ?? '',
        repeatPassword: location.state?.repeatPassword ?? '',
        firstName: location.state?.firstName ?? '',
        termsAccepted: location.state?.termsAccepted ?? false
      });
    }
  }, [resetForm, location.state]);

  const email = watch('email');
  const password = watch('password');
  const repeatPassword = watch('repeatPassword');
  const firstName = watch('firstName');
  const termsAccepted = watch('termsAccepted');

  useEffect(() => {
    isSuccess && navigate(paths.signUpSuccess, { replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, navigate]);

  useEffect(() => {
    isError &&
      dispatch(
        addAlert({
          color: 'danger',
          text: 'Konto z tym adresem e-mail może już istnieć lub został przesłany błędny formularz.'
        })
      );
  }, [isError, dispatch]);

  useEffect(() => {
    setHighlightRules(!!formState.errors.password);
  }, [formState, formState.errors.password]);

  useEffect(() => {
    loggedUser && navigate(paths.signIn, { replace: true });
  }, [loggedUser, navigate]);

  const onSubmit = (data: SignUpFormValues) => {
    const captchaValue = captchaRef.current.getValue();

    if (!captchaValue) {
      dispatch(addAlert({ color: 'warning', text: 'Zweryfikuj CAPTCHA' }));
      return;
    }

    validateRecaptchaToken(captchaValue)
      .unwrap()
      .then(response => {
        if (!response) {
          dispatch(addAlert({ color: 'danger', text: 'Błąd weryfikacji CAPTCHA' }));
        } else {
          captchaRef.current?.reset();
          register({
            email: data.email.toLowerCase(),
            password: data.password,
            firstName: data.firstName,
            termsAccepted: data.termsAccepted
          });
        }
      })
      .catch(() => dispatch(addAlert({ color: 'danger', text: 'Błąd weryfikacji CAPTCHA' })));
  };

  return (
    <RouteLayout>
      <Loadable loading={isLoading}>
        <div className="d-flex flex-column h-100 justify-content-between py-5 mx-3">
          <div>
            <Typography variant="h1">Zarejestruj się</Typography>
            <Form onSubmit={handleSubmit(onSubmit)} noValidate>
              <Form.Group className="mb-3" controlId="signUpForm.firstName">
                <Form.Label>Imię</Form.Label>
                <Controller
                  name="firstName"
                  control={control}
                  render={({ field, fieldState }) => (
                    <>
                      <Form.Control {...field} isInvalid={fieldState.invalid} required />
                      {fieldState.invalid ? <FormError error={fieldState.error?.message} /> : ''}
                    </>
                  )}
                  rules={{ required: validationMessage.required }}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="signUpForm.email">
                <Form.Label>E-mail</Form.Label>
                <Controller
                  name="email"
                  control={control}
                  render={({ field, fieldState }) => (
                    <>
                      <Form.Control {...field} type="email" placeholder="E-mail" isInvalid={fieldState.invalid} required />
                      {fieldState.invalid ? <FormError error={fieldState.error?.message} /> : ''}
                    </>
                  )}
                  rules={{
                    required: validationMessage.required,
                    validate: validateEmail
                  }}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="signUpForm.password">
                <Form.Label>Hasło</Form.Label>
                <Controller
                  name="password"
                  control={control}
                  render={({ field, fieldState }) => (
                    <>
                      <Form.Control {...field} type="password" placeholder="Hasło" isInvalid={fieldState.invalid} required />
                      {fieldState.invalid ? <FormError error={fieldState.error?.message} /> : ''}
                    </>
                  )}
                  rules={{
                    validate: validatePassword
                  }}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="signUpForm.repeatPassword">
                <Form.Label>Powtórz hasło</Form.Label>
                <Controller
                  name="repeatPassword"
                  control={control}
                  render={({ field, fieldState }) => (
                    <>
                      <Form.Control {...field} type="password" placeholder="Powtórz hasło" required isInvalid={fieldState.invalid} />
                      {fieldState.invalid ? <FormError error={fieldState.error?.message} /> : ''}
                    </>
                  )}
                  rules={{
                    validate: (value, formValues) => value === formValues.password || 'Hasła nie są identyczne'
                  }}
                />
              </Form.Group>
              <PasswordRules password={getValues('password')} disabled={!highlightRules} />
              <Form.Group className="my-3 d-flex align-items-center gap-2" controlId="signInForm.termsAccepted">
                <Controller
                  name="termsAccepted"
                  control={control}
                  render={({ field }) => (
                    <Form.Check
                      {...field}
                      value={''}
                      checked={field.value}
                      onChange={e => field.onChange(e.target.checked)}
                      type="checkbox"
                      inline
                      label={
                        <div className="d-flex gap-1" role="button">
                          <Typography variant="description-md" styles={{ color: cGrey600 }}>
                            Akceptuję
                          </Typography>
                          <Button
                            variant="link"
                            onClick={() =>
                              navigate(paths.signUp.terms, {
                                state: { email, password, repeatPassword, firstName, termsAccepted }
                              })
                            }
                            style={{
                              fontSize: pxToRem(16),
                              color: cSecondary,
                              fontWeight: '400',
                              textDecoration: 'underline',
                              padding: 0,
                              margin: 0,
                              border: 'none'
                            }}
                          >
                            Regulamin serwisu
                          </Button>
                        </div>
                      }
                    />
                  )}
                  rules={{
                    validate: value => value === true
                  }}
                />
              </Form.Group>
              {process.env.REACT_APP_RECAPTCHA_KEY && <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_KEY} ref={captchaRef} />}

              <JustifyCenter classNames="pt-5 pb-2">
                <Button type="submit" disabled={!formState.isValid || isLoading}>
                  Zarejestruj się
                </Button>
              </JustifyCenter>
            </Form>
          </div>
          <JustifyCenter>
            <Button variant="outline-primary" onClick={() => navigate(paths.signIn)}>
              Zaloguj się
            </Button>
          </JustifyCenter>
        </div>
      </Loadable>
    </RouteLayout>
  );
};
