import { Alerts } from 'features/alerts/alerts';
import { Typography } from 'features/ui/typography/typography';
import { useEffect, useRef, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { RouterProvider } from 'react-router-dom';
import { router } from 'routing/routes';
import paths from 'routing/utils';
import { APP_VERSION } from 'serviceWorkerRegistration';
import { useAppSelector } from 'store/hooks';
import giftSpiralImg from './shared/images/gift-spiral.png';
import giftImg from './shared/images/gift.svg';

const App = () => {
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState<boolean>(false);
  const timer = useRef<NodeJS.Timeout>();
  // redux
  const loggedUser = useAppSelector(state => state.authSlice.loggedUser);

  useEffect(() => {
    console.info(`App prod version: ${APP_VERSION}`);

    const isStandalone = window.matchMedia('(display-mode: standalone)').matches || (window.navigator as any).standalone;

    timer.current = setTimeout(() => setIsConfirmationDialogOpen(!isStandalone), 2000);
  }, []);

  useEffect(() => {
    if (loggedUser) {
      clearTimeout(timer.current);
    }
  }, [loggedUser]);

  return (
    <>
      <Modal centered show={isConfirmationDialogOpen}>
        <div style={{ position: 'relative', height: 170 }} className="w-100">
          <img src={giftSpiralImg} alt="" style={{ position: 'absolute', top: 0, left: 0, right: 0, margin: 'auto' }} />
          <img src={giftImg} alt="" style={{ position: 'absolute', bottom: 8, left: 0, right: 0, margin: 'auto' }} />
        </div>

        <Modal.Body className="w-100">
          <Typography variant="h1" classNames="pb-1 text-center">
            Gratulacje
          </Typography>
          <Typography classNames="text-center" variant="description">
            Otrzymujesz{' '}
            <Typography variant="h4" inline>
              4 tokeny na start!
            </Typography>
          </Typography>
          <Typography classNames="pb-4 text-center" variant="description">
            Stwórz teraz swoje własne bajki.
          </Typography>

          <Typography classNames="pb-4 text-center" variant="h4">
            Zarejestruj się teraz aby przypisać je do swojego konta.
          </Typography>
          <div className="d-flex flex-column align-items-center justify-content-center gap-3">
            <Button
              className="fullWidth"
              variant="primary"
              onClick={() => {
                router.navigate(paths.signUp.base);
                setIsConfirmationDialogOpen(false);
              }}
            >
              Odbierz nagrodę
            </Button>
            <Button className="fullWidth" variant="outline-secondary" onClick={() => setIsConfirmationDialogOpen(false)}>
              Zrezygnuj
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <Alerts />
      <RouterProvider router={router} />
    </>
  );
};

export default App;
