import { RouteLayout } from 'features/ui/layout/routeLayout';
import { Typography } from 'features/ui/typography/typography';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { router } from 'routing/routes';
import paths from 'routing/utils';
import { AddSquare } from 'shared/icons/addSquare';
import { QuestionMarkIcon } from 'shared/icons/questionMarkIcon';
import { Moral } from 'shared/types/moral';
import { cGrey200, cPrimary, cSecondary } from 'shared/utils/styleCommon';
import { useGetPredefinedMoralsQuery } from 'store/api/endpoints/predefinedMoralsEndpoints';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setMoral, setTempMoral } from 'store/slices/storyCreatorSlice';
import { PickerItemPreview } from '../pickerItemPreview';

const defaultPredefinedMorals: Moral[] = [];

export const MoralPicker = () => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [isMoralInitialized, setIsMoralInitialized] = useState(false);
  // redux
  const dispatch = useAppDispatch();
  const loggedUser = useAppSelector(state => state.authSlice.loggedUser);
  const moral = useAppSelector(state => state.storyCreatorSlice.moral);
  const tempMoral = useAppSelector(state => state.storyCreatorSlice.tempMoral);
  // rtk
  const { data: predefinedMorals = defaultPredefinedMorals } = useGetPredefinedMoralsQuery();
  // other
  const navigate = useNavigate();

  useEffect(() => {
    if (!tempMoral && moral && !isMoralInitialized) {
      dispatch(setTempMoral(moral));
      setIsMoralInitialized(true);
    }
  }, [dispatch, moral, tempMoral, isMoralInitialized]);

  return (
    <RouteLayout
      backRoute={paths.storyCreator.custom.base}
      backRouteAdditionalAction={() => {
        dispatch(setTempMoral(undefined));
      }}
      bottomActions={[
        {
          label: 'Zapisz',
          action: () => {
            dispatch(setMoral(tempMoral));
            dispatch(setTempMoral(undefined));
            router.navigate(paths.storyCreator.custom.base);
          }
        }
      ]}
    >
      <div className="pt-4 mx-3">
        <div className="d-flex justify-content-between">
          <Typography variant="h1">Wybierz morał</Typography>
          <Button variant="link" onClick={() => setIsEditMode(prev => !prev)}>
            {isEditMode ? 'Zakończ' : 'Edytuj'}
          </Button>
        </div>
        <div className="d-flex justify-content-center align-items-start gap-3 mt-5 flex-wrap">
          <div
            role="button"
            className="d-flex flex-column justify-content-start align-items-start gap-2"
            onClick={() => navigate(paths.storyCreator.custom.moral.createMoral)}
          >
            <AddSquare />
            <Typography variant="description" classNames="text-center">
              Dodaj morał
            </Typography>
          </div>
          <div role="button" className="d-flex flex-column justify-content-start" onClick={() => dispatch(setTempMoral({ random: true }))}>
            <div
              style={{
                outline: tempMoral?.random ? `2px solid ${cPrimary}` : `2px solid ${cGrey200}`,
                borderRadius: '10px',
                width: '160px',
                height: '166px'
              }}
              className="d-flex justify-content-center align-items-center"
            >
              <QuestionMarkIcon fill={tempMoral?.random ? cPrimary : '#112B52'} />
            </div>
            <Typography variant="description" classNames="pt-2" styles={{ color: tempMoral?.random ? cPrimary : cSecondary }}>
              Losowy
            </Typography>
          </div>
          {loggedUser?.morals.map(m => (
            <div key={m.value}>
              <PickerItemPreview
                editable={isEditMode}
                isSelected={tempMoral?.moral?.value === m.value}
                filename={m.image}
                directory={loggedUser?.id}
                name={m.value}
                onClickCallback={() => {
                  dispatch(setTempMoral({ random: false, moral: m }));

                  if (isEditMode) {
                    navigate(paths.storyCreator.custom.moral.editMoral + `/${m.value}`);
                  }
                }}
              />
            </div>
          ))}
          {predefinedMorals.map(m => (
            <div key={m.value}>
              <PickerItemPreview
                isSelected={tempMoral?.moral?.value === m.value}
                filename={m.image}
                directory={'common'}
                name={m.value}
                onClickCallback={() => {
                  dispatch(setTempMoral({ random: false, moral: { ...m, predefined: true } }));
                }}
              />
            </div>
          ))}
        </div>
      </div>
    </RouteLayout>
  );
};
