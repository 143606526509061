const paths = {
  root: '/',
  signIn: '/sign-in',
  signUp: {
    base: '/sign-up/base',
    terms: '/sign-up/terms'
  },
  resetPassword: '/reset-password',
  activate: '/activate',
  newPassword: '/new-password',
  oauthSuccess: '/oauth2/redirect-success',
  oauthFailure: '/oauth2/redirect-failure',
  newPasswordSuccess: '/new-password-success',
  resetPasswordSuccess: '/reset-password-success',
  signUpSuccess: '/sign-up-success',
  logout: '/logout',
  subscription: {
    base: '/subscription/base',
    paymentMethods: '/subscription/payment-methods',
    addCard: '/subscription/add-card',
    subscriptionSuccess: '/subscription/error',
    subscriptionError: '/subscription/success'
  },
  profile: {
    base: '/profile/base',
    subscription: '/profile/subscription',
    characters: {
      base: '/profile/characters',
      createHero: '/profile/characters/create-hero',
      editHero: '/profile/characters/edit-hero',
      createChild: '/profile/characters/create-child-profile',
      editChild: '/profile/characters/edit-child-profile'
    },
    plans: '/profile/plans',
    settings: {
      base: '/profile/settings/base',
      changePassword: '/profile/settings/change-password',
      changeEmail: '/profile/settings/change-email'
    },
    help: '/profile/help',
    about: '/profile/about',
    terms: '/profile/terms'
  },
  storyCreator: {
    template: {
      base: '/template-creator',
      recipient: {
        base: '/template-creator/recipient',
        createChild: '/template-creator/recipient/create-child-profile',
        editChild: '/template-creator/recipient/edit-child-profile'
      },
      characters: {
        base: '/template-creator/characters',
        createChild: '/template-creator/characters/create-child-profile',
        editChild: '/template-creator/characters/edit-child-profile',
        createHeroAsMain: '/template-creator/characters/create-hero-as-main',
        editHeroAsMain: '/template-creator/characters/edit-hero-as-main',
        createHeroAsSide: '/template-creator/characters/create-hero-as-side',
        editHeroAsSide: '/template-creator/characters/edit-hero-as-side'
      },
      other: '/template-creator/other',
      narrator: '/template-creator/narrator'
    },
    custom: {
      base: '/custom-creator',
      recipient: {
        base: '/custom-creator/recipient',
        createChild: '/custom-creator/recipient/create-child-profile',
        editChild: '/custom-creator/recipient/edit-child-profile'
      },
      characters: {
        base: '/custom-creator/characters',
        createChild: '/custom-creator/characters/create-child-profile',
        editChild: '/custom-creator/characters/edit-child-profile',
        createHeroAsMain: '/custom-creator/characters/create-hero-as-main',
        editHeroAsMain: '/custom-creator/characters/edit-hero-as-main',
        createHeroAsSide: '/custom-creator/characters/create-hero-as-side',
        editHeroAsSide: '/custom-creator/characters/edit-hero-as-side'
      },
      world: {
        base: '/custom-creator/world/base',
        createWorld: '/custom-creator/world/create-world',
        editWorld: '/custom-creator/world/edit-world'
      },
      moral: {
        base: '/custom-creator/moral/base',
        createMoral: '/custom-creator/moral/create-moral',
        editMoral: '/custom-creator/moral/edit-moral'
      },
      writingStyle: '/custom-creator/writing-style',
      epoch: '/custom-creator/epoch',
      elements: '/custom-creator/elements',
      length: '/custom-creator/length',
      narrator: '/custom-creator/narrator'
    }
  },
  search: {
    base: '/search/base',
    category: {
      base: '/search/category'
    }
  },
  library: '/library',
  story: '/story',
  rating: '/rating',
  series: '/story-series'
};

export default paths;
